import { Component, ViewChild, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Shipment, ShipmentItemsUpdateSet, ShipmentItemsSelection, ShipmentItem, BulkImportRow, BulkImportResponse } from '../../../model/shipment.model';
import { ShipmentService } from '../../../services/shipment/shipment.service';
import { ShipmentGridService } from '../../../services/shipment/shipment.grid.service';
import { SharedService } from '../../../services/shared.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { FormService } from '../../../services/form.service';
import { TableService } from '../../../services/table.service';
import { ToastService } from '../../../services/toastService/toast.service';
import * as moment from 'moment';
import { ShipmentDialogService } from '../shipmentDialogComponent/shipment.dialog.service';
import { QuestionDialogService } from '../../../components/questionDialogComponent/question.dialog.service';
import { ImportShipmentErrorDialogService } from '../importShipmentErrorDialogComponent/import.shipment.error.dialog.service';
import { ExportObj } from '@app/model/table.model';
import { OlderShipmentItemExistenceValidator } from './older-shiment-item-existence-validator/older-shipment-item-existence-validator';

@Component({
  selector: 'shipment-detail',
  templateUrl: 'shipment.detail.component.html',
  styleUrls: ['./shipment.detail.component.scss'],
})
export class ShipmentDetailComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInputElement: any;
    public pastedExcel = '';
    public fromFileObjects: BulkImportRow[] = [];
    private pastedObjects: BulkImportRow[] = [];
    public addFromFileBusy: boolean;
    public addFromPasteBusy: boolean;
    public brands:  any[] = [];
    public selectedBrandFromFile: {id: string, name: string};
    public selectedBrandPasted: {id: string, name: string};

    public shipment: Shipment;
    public totalRows: number;
    public filterItems: any; // TableFilterItem[];
    public totalAmountAll = 0;
    public totalWeightAll = 0;
    public totalShipAmountZeroCount = 0;
    public totalValueAll:      {};
    public totalAmountVisible = 0;
    public totalWeightVisible = 0;
    public totalValueVisible:  {};
    public totalWeightSelected = 0;
    public totalAmountSelected = 0;
    public totalValueSelected = {};
    public subjectRefresh = new Subject<any>();
    public subjectReloadCurrent: Subject<void>;
    public subjectAllSelected: Subject<any>;
    public exportObj: ExportObj;

    public shipmentDate: any;
    public myDatePickerOptions: IMyOptions;
    public pickUpTime: Date;
    public shipmentNote: string;

    public shipmentDetailsEdited = false;

    public busy: boolean;

    public buttonsVisibility = {
        cancelCreate: false,
        cancelChanges: false,
        saveChanges: false,
        cancelShipment: false,
        prepareForShipment: false,
    }
    public objectKeys = Object.keys; // for ngFor iterating over object keys
    private olderShipmentItemExistenceValidator: OlderShipmentItemExistenceValidator;
    private subscriptions: Subscription[] = [];

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    public isEditing(): boolean {
        return this.tableService.isSomethingChanged(this.sharedService.user.preferences.shipmentSelection) || this.shipmentDetailsEdited;
    }

    constructor(
        private cdRef: ChangeDetectorRef,
        private router: Router,
        public route: ActivatedRoute,
        private shipmentService: ShipmentService,
        private shipmentGridService: ShipmentGridService,
        public sharedService: SharedService,
        private formService: FormService,
        public tableService: TableService,
        private shipmentDialogService: ShipmentDialogService,
        private questionDialogService: QuestionDialogService,
        private toastService: ToastService,
        private importShipmentErrorDialogService: ImportShipmentErrorDialogService
    ) {
        const this_ = this;
        this.subjectRefresh = new Subject<any>();
        this.subjectReloadCurrent = new Subject<any>();
        this.subjectAllSelected = this.shipmentGridService.getItemsSelectedSubject();

        this.filterItems = this.getFilterItems();

        this.getShipmentItems = this.getShipmentItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        // brands
        this.sharedService.brands.forEach(function(brand) {
            this_.brands.push({id: brand.code, name: brand.name});
        });
        this.selectedBrandFromFile = this.brands[0];
        this.selectedBrandPasted = this.brands[0];

        this.init();

        // item was selected
        this.subscriptions.push(
            this.shipmentGridService
                .getSelectedSubject()
                .subscribe(selectionEvent => this.onItemSelected(selectionEvent))
        )

        // Multiple items were selected
        this.subscriptions.push(
            this.shipmentGridService
                .getItemsSelectedSubject()
                .subscribe(() => this.onItemMultipleSelected())
        );

        this.route.queryParams.subscribe((param: any) => {
            // save before load question dialog
            if (this.route.snapshot.queryParams['saveBeforeLoad']) {
                this.save(false, true);
                this.subjectRefresh.next();
                return;
            }
        });

        this.olderShipmentItemExistenceValidator =
            new OlderShipmentItemExistenceValidator(this.toastService);
    }

    private init(): void {
        this.myDatePickerOptions = this.formService.getDatePickerOptions();
        const momentDate = this.formService.getClosestDeliveryDateIncluding(true);
        this.shipmentDate = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }

        const d = new Date();
        d.setHours(10);
        d.setMinutes(0);
        this.pickUpTime = d;

        this.shipmentNote = '';

        if (!this.sharedService.user.preferences.hasOwnProperty('shipmentSelection')) {
            this.sharedService.user.preferences.shipmentSelection = this.getEmptyShipmentSelection();
        }
        if (!this.sharedService.user.preferences.shipmentSelection.newSelectedIds) {this.sharedService.user.preferences.shipmentSelection.newSelectedIds = {}; }
        if (!this.sharedService.user.preferences.shipmentSelection.deselectedIds) {this.sharedService.user.preferences.shipmentSelection.deselectedIds = {}; }
        if (!this.sharedService.user.preferences.shipmentSelection.updatedIds) {this.sharedService.user.preferences.shipmentSelection.updatedIds = {}; }
        this.totalAmountSelected = this.sharedService.user.preferences.shipmentSelection.totalAmountSelected ? this.sharedService.user.preferences.shipmentSelection.totalAmountSelected : 0;
        this.totalWeightSelected = this.sharedService.user.preferences.shipmentSelection.totalWeightSelected ? this.sharedService.user.preferences.shipmentSelection.totalWeightSelected : 0;
        // this.totalValueSelected = this.sharedService.user.preferences.shipmentSelection.totalValueSelected ? this.sharedService.user.preferences.shipmentSelection.totalValueSelected : 0;
        this.totalValueSelected = this.sharedService.user.preferences.shipmentSelection.totalValueSelected ? this.sharedService.user.preferences.shipmentSelection.totalValueSelected : {};
    }

    private getEmptyShipmentSelection(): ShipmentItemsSelection {
        return {
            ids: {}, 
            all: false, 
            visible: false,
            totalAmount: 0,
            totalWeight: 0,
            totalValue: {},
            totalAmountSelected: 0,
            totalWeightSelected: 0,
            totalValueSelected: {}
        };
    }

    ngOnInit() {
        const restoredColState = this.sharedService.user.preferences['shipmentTableColumnsState'];
        if (this.route.snapshot.params['id'] !== 'new') {
            this.shipmentService.getShipmentDetails(this.route.snapshot.params['id'])
            .subscribe(data => {
                this.shipment = data;

                const exportFunction = function(query) {
                    return this.shipmentService.exportDetail(this.shipment.id, query);
                };
                this.sharedService.translateService.get('SHIPMENT').subscribe(translation => {
                    this.exportObj = {
                        fileName: translation + '_' + this.shipment.shipmentNumberEComm,
                        export: exportFunction.bind(this)
                    };
                });

                this.buttonsVisibility = this.shipmentService.getVisibilityOfButtons(this.route.snapshot.params['id'], this.shipment);

                const momentDate = moment(this.shipment.shipmentDateRequested);
                this.shipmentDate = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }
                this.pickUpTime = new Date(this.shipment.shipmentDateRequested);
                this.shipmentNote = this.shipment.shipmentNote;
                this.cdRef.detectChanges();
                this.subjectRefresh.next();
            }, err => {
                console.log(err)
            });
        } else {
            this.shipment = new Shipment(0, '', new Date(this.pickUpTime.getTime()), '', '', '', '', '', '', '', '', '', '', '', '', '');
            if (restoredColState && restoredColState !== '' && restoredColState.length > 0) {
                restoredColState[0].hide = false;
            }
            this.buttonsVisibility = this.shipmentService.getVisibilityOfButtons(this.route.snapshot.params['id'], this.shipment);
            this.sharedService.translateService.get('SHIPMENT').subscribe(translation => {
                this.exportObj = {
                    fileName: translation,
                    export: (query) => this.shipmentService.exportShipmentItems(query)
                };
            });
        }

        const this_ = this;
        setTimeout(function() {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastShipmentQueryParams);
        }, 0);

        // this.route.queryParams.subscribe(params => {
        // 	if (!this.route.snapshot.queryParams['saveBeforeLoad']) {
        // 		this.subjectRefresh.next(parseInt(this.route.snapshot.queryParams['page']));
        // 	}
        // });
    }

    onDateChanged(event: IMyDateModel) {
        this.shipmentDate = event.jsdate;
        this.shipmentDetailsEdited = true;
    }

    noteChanged() {
        this.shipmentDetailsEdited = true;
    }

    timeChanged() {
        if (moment(this.pickUpTime).hour() !== moment(this.shipment.shipmentDateRequested).hour() ||
            moment(this.pickUpTime).minute() !== moment(this.shipment.shipmentDateRequested).minute()) {
            this.shipmentDetailsEdited = true;
        }
    }

    getShipmentItems(params) {
        const this_ = this;
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        if (this.route.snapshot.queryParams.hasOwnProperty('toShip') && !this_.sharedService.lastShipmentQueryParams['toShip']) {
            this_.sharedService.lastShipmentQueryParams['toShip'] = this.route.snapshot.queryParams['toShip'];
            query.toShip = this.route.snapshot.queryParams['toShip'];
            // delete this.sharedService.user.preferences.shipmentSelection.totalWeightSelected;
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        }  else {
            delete this_.sharedService.lastShipmentQueryParams['toShip'];
            // delete this.sharedService.user.preferences.shipmentSelection.totalWeightSelected;
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        }

        this.sharedService.lastShipmentQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastShipmentQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.shipmentService.getShipmentItems(this.route.snapshot.params['id'], query)
        .subscribe(data => {
            this.totalRows = data.totalCount;
            this.totalShipAmountZeroCount = data.totalShipAmountZeroCount;
            this.totalAmountAll = data.totalShipAmount;
            this.totalWeightAll = Math.round(data.totalWeight * 10000) / 10000;
            this.totalValueAll  = Object.keys(data.totalPrice).reduce((acc, key) => Object.assign(acc, {[key]: Math.round(data.totalPrice[key] * 10000) / 10000}), {});
            this.totalAmountVisible = data.totalAmountCurrent;
            this.totalWeightVisible = Math.round(data.totalWeightCurrent * 10000) / 10000;
            this.totalValueVisible  = Object.keys(data.totalValueCurrent).reduce((acc, key) => Object.assign(acc, {[key]: Math.round((data.totalValueCurrent[key] || 0) * 10000) / 10000}), {});

            if (Object.keys(this.totalValueSelected).length === 0) {
                this.totalAmountSelected = this.sharedService.user.preferences.shipmentSelection.totalAmountSelected = data.totalShipAmount;
                this.totalWeightSelected = this.sharedService.user.preferences.shipmentSelection.totalWeightSelected = Math.round(data.totalShipWeight * 10000) / 10000;
                this.totalValueSelected  = this.sharedService.user.preferences.shipmentSelection.totalValueSelected =
                    Object.keys(data.totalShipPrice).reduce((acc, key) => Object.assign(acc, {[key]: Math.round(data.totalShipPrice[key] * 10000) / 10000}), {});
            }

            if (this.sharedService.user.preferences.shipmentSelection && this.sharedService.user.preferences.shipmentSelection.visible) {
                this.totalAmountSelected = this.totalAmountVisible;
                this.totalWeightSelected = this.totalWeightVisible;
                this.totalValueSelected = this.totalValueVisible;
                this.sharedService.user.preferences.shipmentSelection.totalAmountSelected = this.totalAmountSelected;
                this.sharedService.user.preferences.shipmentSelection.totalWeightSelected = this.totalWeightSelected;
                this.sharedService.user.preferences.shipmentSelection.totalValueSelected = this.totalValueSelected;
            }
            params.successCallback(data.rows, data.totalCount);
            this.busy = false;
            this.loadShipmentItemsForOlderShipmentItemsExistenceValidation();
        }, err => {
            console.log(err);
            this.busy = false;
        });
    }

    private loadShipmentItemsForOlderShipmentItemsExistenceValidation(): void {
        this.shipmentService.getShipmentItems(this.route.snapshot.params['id'], {}).subscribe(data => {
            this.olderShipmentItemExistenceValidator.makeValidationMap(
                data.rows,
                new Set(Object.keys(this.sharedService.user.preferences.shipmentSelection.ids)),
            );
        });
    }

    getGridOptions() {
        return this.shipmentGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10), this.shipment);
    }
    getFilterItems() {
        return this.shipmentGridService.getFilterItems();
    }
    getColumns() {
        return this.shipmentGridService.getColumns();
    }
    getColumnsDefs() {
        return this.shipmentGridService.getColumnDefs(this.shipment);
    }

    getSelectedCount() {
        return this.sharedService.user.preferences.shipmentSelection ?
            Object.keys(this.sharedService.user.preferences.shipmentSelection.ids).length : 0;
    }


    printExportItems() {
        console.log('printExportItems');
    }

    public prepareForShiping() {
        // validate
        if (!this.validateShipmentValues()) {
            return;
        }
        this.shipmentDialogService.confirm({
            myDatePickerOptions: this.myDatePickerOptions,
            shipmentDate: this.shipmentDate,
            pickUpTime: this.pickUpTime,
            shipmentNote: this.shipmentNote,
        }).subscribe(
            res => {
                if (typeof res === 'object') {
                    this.shipmentDate = res.shipmentDate;
                    this.pickUpTime = res.pickUpTime;
                    this.shipmentNote = res.shipmentNote;
                    this.save(true);
                }
            }
        );
    }

    public save(prepareForShipment?: boolean, suppressRedirectToShipments?: boolean) {
        // if nothing was selected
        if (this.tableService.isSelectionEmpty(this.sharedService.user.preferences.shipmentSelection)) {
            const questionParams = {
                message: 'SHIPMENT_EMPTY_MESSAGE',
                question: prepareForShipment || this.shipment.shipmentState === '' ? 'SHIPMENT_EMPTY_PREPARE_QUESTION' : 'SHIPMENT_EMPTY_DRAFT_QUESTION',
                suppressQuestionMark: prepareForShipment || this.shipment.shipmentState === '',
                primary:  prepareForShipment || this.shipment.shipmentState === '' ? 'OK' : 'YES',
                secondary: prepareForShipment || this.shipment.shipmentState === '' ? null : 'NO',
            }
            this.questionDialogService.confirm(
                questionParams
            ).subscribe(
                res => {
                    if (res === 'confirm' && !prepareForShipment && this.route.snapshot.params['id'] !== 'new') {
                        this.saveShipment(prepareForShipment, true);
                    }
                }
            );
        } else {
            this.saveShipment(prepareForShipment, suppressRedirectToShipments);
        }
    }

    private saveShipment(prepareForShipment?: boolean, suppressRedirectToShipments?: boolean) {
        const this_ = this;
        // validate
        if (!this.validateShipmentValues()) {
            return;
        }
        const dataToSave = this.getObjectToSave();
        if (prepareForShipment) {
            dataToSave.action = 'CREATE_DELIVERY_ORDER';
        }

        this.shipmentService.saveShipment(this.route.snapshot.params['id'], dataToSave)
        .subscribe(data => {
            this.sharedService.user.preferences.shipmentSelection.newSelectedIds = {};
            this.sharedService.user.preferences.shipmentSelection.deselectedIds = {};
            this.sharedService.user.preferences.shipmentSelection.updatedIds = {};
            this.shipmentDetailsEdited = false;
            delete this.sharedService.user.preferences.shipmentSelection.totalWeightSelected; // to refresh from server after save
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            if (!suppressRedirectToShipments) {
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipments'], {queryParams: this.sharedService.lastShipmentsQueryParams, replaceUrl: true});

                if (prepareForShipment) { // new delivery order was created
                    if (this.sharedService.hasPermission('delivery-orders', 'GET')) {
                        this.questionDialogService.confirm(
                            {
                                message: 'DELIVERY_ORDER_CREATE_SUCCESS',
                                question: 'DELIVERY_ORDER_GO_TO_NEW',
                                primary: 'YES',
                                secondary: 'NO',
                            }
                        ).subscribe(
                            res => {
                                if (res === 'confirm') {
                                    this.sharedService.backUrl = 'shipments'
                                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/delivery-order/' + data.newDeliveryOrder.id]);
                                }
                            }
                        );
                    } else {
                        this.toastService.addSuccess('DELIVERY_ORDER_CREATE_SUCCESS');
                    }
                }
            } else {
                this.sharedService.lastShipmentQueryParams = Object.assign({}, this.route.snapshot.queryParams);
                delete this.sharedService.lastShipmentQueryParams['saveBeforeLoad'];
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipment/' + data.shipment.id], {queryParams: this.sharedService.lastShipmentQueryParams, replaceUrl: true});

                setTimeout(function() {
                    this_.ngOnInit();
                }, 0);
            }
        }, err => {
            console.log(err)
            if (err.error.message === 'Wrong shipment amount') {
                this.questionDialogService.confirm(
                    {
                        message: 'SHIPMENT_PREPARE_FOR_SHIPMENT_ERROR',
                        question: 'AMOUNT_SHIPMENT_MORE_THAN_AVAILABLE_TO_SHIP_MESSAGE',
                        suppressQuestionMark: true,
                        primary: 'Ok',
                    }
                ).subscribe(
                    res => {
                        if (res === 'confirm' && this.shipment.shipmentState !== 'S') {
                            delete dataToSave.action;
                            this.shipmentService.saveShipment(this.route.snapshot.params['id'], dataToSave)
                            .subscribe(data => {
                                this.sharedService.user.preferences.shipmentSelection.newSelectedIds = {};
                                this.sharedService.user.preferences.shipmentSelection.deselectedIds = {};
                                this.sharedService.user.preferences.shipmentSelection.updatedIds = {};
                                this.shipmentDetailsEdited = false;
                                localStorage.setItem('user', JSON.stringify(this.sharedService.user));

                                if (this.route.snapshot.params['id'] === 'new') {
                                    this.sharedService.lastShipmentQueryParams.toShip = 1;
                                    this.sharedService.lastShipmentQueryParams['amountShipment.operator'] = 'ne';
                                    this.sharedService.lastShipmentQueryParams['amountShipment.value'] = '-1';
                                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipment/' + data.shipment.id], {queryParams: this.sharedService.lastShipmentQueryParams, replaceUrl: true});
                                    setTimeout(function() {
                                        this_.ngOnInit();
                                    }, 0);
                                } else {
                                    const newQuery = Object.assign({}, this.sharedService.lastShipmentQueryParams);
                                    newQuery['toShip'] = 1;
                                    newQuery['amountShipment.operator'] = 'ne';
                                    newQuery['amountShipment.value'] = '-1';
                                    this.router.navigate([], {queryParams: newQuery, replaceUrl: true});
                                    setTimeout(function() {
                                        this_.subjectRefresh.next();
                                    }, 0);
                                }
                            }, err2 => {
                                console.log(err2)
                            });

                        }
                    }
                );
            }
            if (err.error.message === 'Multiple currencies in items') {
                this.questionDialogService.confirm(
                    {
                        message: 'SHIPMENT_PREPARE_FOR_SHIPMENT_ERROR',
                        question: 'SHIPMENT_PREPARE_FOR_SHIPMENT_MULTIPLE_CURRENCIES_MESSAGE',
                        suppressQuestionMark: true,
                        primary: 'Ok',
                    }
                ).subscribe(
                    res => {
                        if (res === 'confirm') {
                            delete dataToSave.action;
                            this.shipmentService.saveShipment(this.route.snapshot.params['id'], dataToSave)
                            .subscribe(data => {
                                if (this.route.snapshot.params['id'] === 'new') {
                                    this.sharedService.lastShipmentQueryParams.toShip = 1;
                                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipment/' + data.shipment.id], {queryParams: this.sharedService.lastShipmentQueryParams, replaceUrl: true});
                                    setTimeout(function() {
                                        this_.ngOnInit();
                                    }, 0);
                                } else {
                                    const newQuery = Object.assign({}, this.sharedService.lastShipmentQueryParams);
                                    newQuery['toShip'] = 1;
                                    this.router.navigate([], {queryParams: newQuery, replaceUrl: true});
                                    setTimeout(function() {
                                        this_.subjectRefresh.next();
                                    }, 0);
                                }
                            })
                        }
                    }
                );
            }
        });
    }

    validateShipmentValues () {
        if (!this.shipmentDate) {
            this.questionDialogService.confirm(
                {
                    message: 'INVALID_DATA_TYPE:_DELIVERYDATE_ERROR',
                    question: 'REQUESTED_EXPEDITION_DATE',
                    suppressQuestionMark: true,
                    primary: 'CLOSE',
                }
            ).subscribe(
                res => {
                }
            );
            return null;
        }
        return true;
    }

    private getObjectToSave(): ShipmentItemsUpdateSet {
        const obj: ShipmentItemsUpdateSet = {
            shipmentDateRequested: this.formService.getIsoStringFromDatepickerModel(this.shipmentDate, this.pickUpTime),
            shipmentNote: this.shipmentNote,
            items: {
                toInsert: [],
                toDelete: [],
                toUpdate: []
            }
        };
        const shipmentSelection = this.sharedService.user.preferences.shipmentSelection;
        for (const key of Object.keys(shipmentSelection.newSelectedIds)) {
            obj.items.toInsert.push({
                id: key,
                // shipmentAmount: shipmentSelection.newSelectedIds[key].amountShipment
                shipmentAmount: shipmentSelection.newSelectedIds[key].amountInPacking * shipmentSelection.newSelectedIds[key].packingsToShip
            });
        }
        for (const key of Object.keys(shipmentSelection.deselectedIds)) {
            obj.items.toDelete.push({
                id: key,
                shipmentAmount: shipmentSelection.deselectedIds[key].amountShipment
            });
        }
        for (const key of Object.keys(shipmentSelection.updatedIds)) {
            obj.items.toUpdate.push({
                id: key,
                shipmentAmount: shipmentSelection.updatedIds[key].amountInPacking * shipmentSelection.updatedIds[key].packingsToShip
            });
        }

        return obj;
    }

    // item was selected
    private onItemSelected(selectionEvent: {data: ShipmentItem, selected: boolean}): void {
        const { data, selected } = selectionEvent;

        this.olderShipmentItemExistenceValidator.validateShipmentAfterSelection({
            id: data.id,
            brandCode: data.brandCode,
            eCommProductId: data.eCommProductId,
        }, selected);

        const amountShipment = data.packingsToShip && data.amountInPacking
            ? +data.packingsToShip * data.amountInPacking
            : 0;

        const preference: ShipmentItemsSelection = this.sharedService.user.preferences.shipmentSelection;
        const rowWeight  = data.weight * amountShipment;
        const rowValue   = data.unitPrice * amountShipment;
        
        if (selected) {
            if (data.id in preference.updatedIds) {  // amountShipment change in an existing selected row
                let originalAmountShipment = +data.originalValues.find(item =>
                    item.colId === 'packingsToShip').value * data.amountInPacking;
                originalAmountShipment = originalAmountShipment === amountShipment ? 0 : originalAmountShipment;
                this.totalAmountSelected = this.totalAmountSelected - originalAmountShipment + amountShipment;
                this.totalWeightSelected = this.totalWeightSelected - (originalAmountShipment * data.weight) + rowWeight;
                this.totalValueSelected[data.currency] = this.totalValueSelected[data.currency] -
                    (originalAmountShipment * data.unitPrice) + rowValue;
            } else {  // a new row has been selected
                this.totalAmountSelected = this.totalAmountSelected + amountShipment;
                this.totalWeightSelected = Math.round((this.totalWeightSelected + rowWeight) * 10000) / 10000;
                this.totalValueSelected[data.currency] = Math.round(((this.totalValueSelected[data.currency] || 0) + rowValue) * 10000) / 10000;
            }
        } else {
            this.totalAmountSelected = this.totalAmountSelected - amountShipment;
            this.totalWeightSelected = Math.round((this.totalWeightSelected - rowWeight) * 10000) / 10000;
            this.totalValueSelected[data.currency] = Math.round(((this.totalValueSelected[data.currency] || 0) - rowValue) * 10000) / 10000;
        }
        preference.totalAmount = preference.totalAmountSelected = this.totalAmountSelected;
        preference.totalWeight = preference.totalWeightSelected = this.totalWeightSelected;
        preference.totalValue  = preference.totalValueSelected  = this.totalValueSelected;
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));
    }

    // items was multiple selected
    private onItemMultipleSelected(): void {
        const preference: ShipmentItemsSelection = this.sharedService.user.preferences.shipmentSelection;
        this.totalAmountSelected = 0;
        this.totalWeightSelected = 0;
        this.totalValueSelected = {};
        if (preference.visible) {
            this.totalAmountSelected = this.totalAmountVisible;
            this.totalWeightSelected = this.totalWeightVisible;
            this.totalValueSelected = this.totalValueVisible;
            preference.totalAmount = this.totalAmountSelected;
            preference.totalWeight = this.totalWeightSelected;
            preference.totalValue = this.totalValueSelected;
        }
        if (preference.all) {
            this.totalAmountSelected = this.totalAmountAll;
            this.totalWeightSelected = this.totalWeightAll;
            this.totalValueSelected = this.totalValueAll;
            preference.totalAmount = this.totalAmountAll;
            preference.totalWeight = this.totalWeightAll;
            preference.totalValue = this.totalValueAll;
        }
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));

        this.olderShipmentItemExistenceValidator.updateSelection(new Set(Object.keys(preference.ids)));
    }

    cancelShipment() {         
        this.questionDialogService.confirm(
            {
                message: 'EXPEDITION_CANCEL',
                question: 'SHIPMENT_CANCEL_QUESTION',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    this.shipmentService.cancelShipment(this.route.snapshot.params['id'])
                    .subscribe(data => {
                        this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipments'], {queryParams: this.sharedService.lastShipmentsQueryParams});
                    }, err => {
                        console.log(err)
                    });
                }
            }
        );
    }



    /**
     * File was chosen in file input for order from file, call get objects
     *
     * @param fileInput - chosen file
     */
    fileChangeEvent(fileInput: any) {
        const this_ = this;
          if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e: any) {
                this_.fromFileObjects = this_.getObjectsFromSeparatedString(<string>reader.result, this_.selectedBrandPasted.id);
            }

            reader.readAsText(fileInput.target.files[0]);
        }
    }

    /**
     * Text was pasted or changed in textarea for clippboard import of order
     *
     * @param newValue - string of changed value of textarea
     */
    public pasteExcelChanged(newValue: string): void {
        this.pastedObjects = this.getObjectsFromSeparatedString(newValue, this.selectedBrandFromFile.id);
    }

    /**
     * Change tab key to \t and not go to next focusable element when tab is pressed
     * @param event - keydown event
     * @param element - textarea html element
     */
    public pasteExcelKeyDown(event, element): boolean {
        if (event.keyCode === 9) {
            const v = element.value,
                s = element.selectionStart,
                e = element.selectionEnd;
            element.value = v.substring(0, s) + '\t' + v.substring(e);
            element.selectionStart = element.selectionEnd = s + 1;
            this.pasteExcelChanged(element.value);
            return false;
        }
    }

    /**
     * Returns object parsed from inpud separated string string
     *
     * @param separatedString  - string separated by new lines and by separator in lines
     * @param separator  - separator used in lines
     */
    private getObjectsFromSeparatedString(separatedString: string, brandCode: string): BulkImportRow[] {
        const objects: BulkImportRow[] = [];
        let separator: string;
        const rows = separatedString.split(/\r\n|\r|\n/g);
        if (rows.length > 0) {
            separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
        }
        rows.forEach(function(row: string) {
            if (row.length > 0) {
                let amount = '';
                let code = '';
                let orderNumberEComm = '';
                const splittedRowBySeparator = row.split(separator);
                if (splittedRowBySeparator.length > 0) {
                    code = splittedRowBySeparator[0];
                }
                if (splittedRowBySeparator.length > 1) {
                    amount = splittedRowBySeparator[1].replace(/\ /g, '');
                }
                if (splittedRowBySeparator.length > 2) {
                    orderNumberEComm = splittedRowBySeparator[2];
                }

                if (amount.length > 0 || code.length > 0 || orderNumberEComm.length > 0) {
                    objects.push({shipmentAmount: amount, eCommProductId: code, brandCode: brandCode, orderNumberEComm: orderNumberEComm});
                }
            }
        });
        return objects
    }

    /**
     * Adds products to order from object parsed from file
     */
    fileCsvCreateShipment() {
        this.addFromFileBusy = true;
        this.shipmentService.createShipmentFromImportedData(this.fromFileObjects)
        .subscribe(data => {
            // reset file input
            this.fileInputElement.nativeElement.value = '';
            this.fromFileObjects = [];
            this.addFromFileBusy = false;
            if (data.someShipmentAmountWasRoundedToZero) {
                this.toastService.addError('SOME_SHIPMENT_AMOUNT_IS_ZERO', 8000);
            } else if (data.someShipmentAmountWasRounded) {
                this.toastService.addError('SOME_SHIPMENT_AMOUNT_WAS_ROUNDED', 8000);
            }
            if (data.errors && data.errors.length > 0) {
                this.showImportShipmentErrorDialog(data);
            } else {
                this.showDialogAndGoToNew(data.shipmentId);
            }
        }, err => {
            this.addFromFileBusy = false;
            console.log(err);
        });
    }

    public ctrlVCreateShipment(): void {
        this.addFromPasteBusy = true;
        this.shipmentService.createShipmentFromImportedData(this.pastedObjects)
        .subscribe(data => {
            // reset textarea input
            this.pastedExcel = '';
            this.pastedObjects = [];
            this.addFromPasteBusy = false;
            if (data.someShipmentAmountWasRoundedToZero) {
                this.toastService.addError('SOME_SHIPMENT_AMOUNT_IS_ZERO', 8000);
            } else if (data.someShipmentAmountWasRounded) {
                this.toastService.addError('SOME_SHIPMENT_AMOUNT_WAS_ROUNDED', 8000);
            }
            if (data.errors && data.errors.length > 0) {
                this.showImportShipmentErrorDialog(data);
            } else {
                this.showDialogAndGoToNew(data.shipmentId);
            }
        }, err => {
            this.addFromPasteBusy = false;
            console.log(err);
        });
    }

    /**
     * Open dialog and go to new shipment of confirm
     */
    showDialogAndGoToNew(newShipmentId: number) {
        const this_ = this;
        this.questionDialogService.confirm(
            {
                message: 'SHIPMENT_CREATE_SUCCESS',
                question: 'SHIPMENT_GO_TO_NEW',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    this.shipment = null;
                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipment/' + newShipmentId], {queryParams: this.sharedService.lastShipmentQueryParams});
                    setTimeout(function() {
                        this_.ngOnInit();
                        this_.subjectReloadCurrent.next();
                    }, 0);
                } else {
                    this.subjectReloadCurrent.next();
                }
            }
        );
    }

    /**
     * Shows error dialog of import to basket
     */
    private showImportShipmentErrorDialog(data: BulkImportResponse): void {
        const this_ = this;
        this.importShipmentErrorDialogService.confirm(data).subscribe(
            res => {
                if (res && res.reload) { // reload whole shipment
                    setTimeout(function() {
                        this_.ngOnInit();
                        this_.subjectReloadCurrent.next();
                    }, 0);
                } else {
                    this.subjectReloadCurrent.next();
                }
            }
        );
    }

    public filterShipAmountZero(): void {
        this.router.navigate([], { queryParams: {
            orderBy: this.route.snapshot.queryParams['orderBy'],
            'amountShipment.operator': 'ne',
            'amountShipment.value': -1,
            'toShip': 1
        }, replaceUrl: true });

        this.subjectReloadCurrent.next();
    }

    /**
     * Onselect brand
     */
    onSelectBrandFromFile(item) {
        // console.log(item);
        this.selectedBrandFromFile = {id: item.selected.id, name: item.selected.name};
    }
    onSelectBrandPasted(item) {
        // console.log(item);
        this.selectedBrandPasted = {id: item.selected.id, name: item.selected.name};
    }

    ngOnDestroy() {
        if (this.sharedService.isUserLoggedIn()) { // because could be loagout
            this.sharedService.user.preferences.shipmentSelection = this.getEmptyShipmentSelection();
        }
        this.cdRef.detach();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
      }
}
