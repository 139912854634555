import { ShipmentItem } from "../../../../model/shipment.model";
import { ToastService } from "../../../../services/toastService/toast.service";

type ShipmentValidationRecord = {
  id: string;
  brandCode: string;
  eCommProductId: string;
  orderNumberEComm: string;
  orderCreated: string;
  selected: boolean;
};

type ShipmentValidationMap = Map<string, ShipmentValidationRecord[]>;

export class OlderShipmentItemExistenceValidator {
  private shipmentValidationMap: ShipmentValidationMap = new Map();

  constructor(private toastService: ToastService) {}

  /**
   * Creates a map of shipment items grouped by brandCode and eCommProductId
   * Should be called once after shipment items are loaded
   */
  public makeValidationMap(
      rows: ShipmentItem[],
      selectedIds: Set<string>
  ): void {
    const validationMap = new Map<string, ShipmentValidationRecord[]>();

    const insertByOrderCreated = (
      arr: ShipmentValidationRecord[],
      record: ShipmentValidationRecord
    ) => {
      const index = arr.findIndex(
        (item) => item.orderCreated > record.orderCreated
      );
      if (index === -1) {
        arr.push(record);
      } else {
        arr.splice(index, 0, record);
      }
    };

    rows.forEach((row) => {
      const key = this.makeKeyForValidationMap(
        row.brandCode,
        row.eCommProductId
      );

      if (!validationMap.has(key)) {
        validationMap.set(key, []);
      }
      insertByOrderCreated(validationMap.get(key)!, {
        id: row.id,
        brandCode: row.brandCode,
        eCommProductId: row.eCommProductId,
        orderNumberEComm: row.orderNumberEComm,
        orderCreated: row.orderCreated.toString(), // TODO: row.orderCreated is typed as Date, but it's used as string
        selected: selectedIds.has(row.id),
      });
    });
    this.shipmentValidationMap = validationMap;
  }

  /**
   * Makes validation after a shipment item is selected or deselected
   * Should be called after a shipment item is selected or deselected
   */
  public validateShipmentAfterSelection(
    {
        id,
        brandCode,
        eCommProductId,
    }: {
        id: string;
        brandCode: string;
        eCommProductId: string;
    },
    selected: boolean
  ): void {
    if (!selected) {
      this.updateValidationMap({
        id,
        brandCode,
        eCommProductId,
        selected: false,
      });
      return;
    }
    this.updateValidationMap({
        id,
        brandCode,
        eCommProductId,
        selected: true,
    });

    const key = this.makeKeyForValidationMap(brandCode, eCommProductId);
    const records = this.shipmentValidationMap.get(key);

    if (!records || records.length === 0) {
      console.error(`Records not found for key ${key}`);
      return;
    }

    const olderRecord = this.findOlderRecordThenOneWithId(records, id);
    if (olderRecord) {
      console.log(
        "OlderShipmentItemExistenceValidator: older record exists",
        olderRecord
      );
      this.toastService.addErrorToast(
        "SHIPMENT_OLDER_ORDER_EXISTS_WITH_NO",
        {
          interpolation: {
              orderNumberEComm: olderRecord.orderNumberEComm,
          },
        }
      );
    }
  }

  /**
   * Initial set of selected shipment items or update after multiple selection
   * @param selectedIds Set of selected shipment item ids
   */
  public updateSelection(selectedIds: Set<string>): void {
    this.shipmentValidationMap.forEach((records) => {
      records.forEach((record) => {
        record.selected = selectedIds.has(record.id);
      });
    });
  }

  private updateValidationMap({
    id,
    brandCode,
    eCommProductId,
    selected,
  }: {
    id: string;
    brandCode: string;
    eCommProductId: string;
    selected: boolean;
  }): void {
    const key = this.makeKeyForValidationMap(brandCode, eCommProductId);

    if (this.shipmentValidationMap.has(key)) {
      const records = this.shipmentValidationMap.get(key);
      const record = records?.find((r) => r.id === id);
      if (record) {
        record.selected = selected;
      } else {
        console.error(
            `Record with id ${id} not found in shipmentValidationMap`
        );
      }
    } else {
      console.error(`Key ${key} not found in shipmentValidationMap`);
    }
  }

  // `records` are cronologically ordered by orderCreated
  private findOlderRecordThenOneWithId(
    records: ShipmentValidationRecord[],
    id: string
  ): ShipmentValidationRecord | undefined {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.id === id) {
        return;
      }
      if (!record.selected) {
        return record;
      }
    }

    throw new Error(`Record with id ${id} not found in records`);
  }

  private makeKeyForValidationMap(
    brandCode: string,
    eCommProductId: string
  ): string {
    return `${brandCode}|${eCommProductId}`;
  }
}
